export const data = [
  ["1", "Saksham Shrestha", "1", "3.6500000000000004", "COMPLETED", "6", "NURSERY", "OUTSTANDING"],
  ["2", "Samir Kumal", "2", "3.6", "COMPLETED", "7", "NURSERY", "OUTSTANDING"],
  ["3", "Nemo Shrestha", "3", "4", "COMPLETED", "3", "NURSERY", "OUTSTANDING"],
  ["4", "Krishna Ramdam", "4", "4", "COMPLETED", "1", "NURSERY", "OUTSTANDING"],
  ["5", "Salina Kumal", "5", "4", "COMPLETED", "2", "NURSERY", "OUTSTANDING"],
  ["6", "Samikshya Kumal", "6", "3.7", "COMPLETED", "5", "NURSERY", "OUTSTANDING"],
  ["7", "Jenisha Shahi", "7", "4", "COMPLETED", "3", "NURSERY", "OUTSTANDING"],
  ["8", "Aaliya Khatun", "8", "3.45", "COMPLETED", "8", "NURSERY", "EXCELLENT"],
  ["1", "Unik Nepali", "1", "4", "PASS", "2", "LKG", "OUTSTANDING"],
  ["2", "Aarya Kumal", "2", "3.75", "PASS", "3", "LKG", "OUTSTANDING"],
  ["3", "Shirisha Ale", "3", "3.25", "PASS", "5", "LKG", "EXCELLENT"],
  ["4", "Pramisha Ale", "4", "0", "ABSENT", "9", "LKG", "ABSENT"],
  ["5", "Suhana Sunar", "5", "4", "PASS", "1", "LKG", "OUTSTANDING"],
  ["6", "Sandhya Nepali", "6", "3.35", "PASS", "4", "LKG", "EXCELLENT"],
  ["7", "Suraj Patel", "7", "2.9000000000000004", "PASS", "7", "LKG", "VERY GOOD"],
  ["8", "Muraj Patel", "8", "2.8500000000000005", "PASS", "8", "LKG", "VERY GOOD"],
  ["9", "Asif Alam", "9", "3.35", "PASS", "6", "LKG", "EXCELLENT"],
  ["1", "Kanchan Bhattarai", "1", "3.9", "PASS", "3", "UKG", "OUTSTANDING"],
  ["2", "Utsav Bk", "2", "3.7500000000000004", "PASS", "4", "UKG", "OUTSTANDING"],
  ["3", "Ekata Bhandari", "3", "4", "PASS", "1", "UKG", "OUTSTANDING"],
  ["4", "Riya BK", "4", "4", "PASS", "2", "UKG", "OUTSTANDING"],
  ["5", "Dipshan Bk", "5", "3.45", "PASS", "6", "UKG", "EXCELLENT"],
  ["6", "Sabin Kumal", "6", "3.15", "PASS", "9", "UKG", "VERY GOOD"],
  ["7", "Bipin Sunar", "7", "3.55", "PASS", "5", "UKG", "EXCELLENT"],
  ["8", "Milan BK", "8", "3.0000000000000004", "PASS", "10", "UKG", "VERY GOOD"],
  ["9", "Karuna Kumari Shah", "9", "3.3", "PASS", "7", "UKG", "EXCELLENT"],
  ["10", "Shakris Bk", "10", "3.15", "PASS", "8", "UKG", "VERY GOOD"],
  ["1", "Saraswoti Chaudhary", "1", "3.8000000000000003", "COMPLETED", "1", "ONE", "OUTSTANDING"],
  ["2", "Navya Pariyar", "2", "3.7", "COMPLETED", "3", "ONE", "OUTSTANDING"],
  ["3", "Prashant Kumal", "3", "3.75", "COMPLETED", "2", "ONE", "OUTSTANDING"],
  ["4", "Sonika Ale", "4", "0.2", "COMPLETED", "7", "ONE", "NOTGRADED"],
  ["5", "Bibek Ale", "5", "0.6", "WITHHELD", "6", "ONE", "NOTGRADED"],
  ["6", "Sabin Shrestha", "6", "2.1999999999999997", "WITHHELD", "4", "ONE", "ACCEPTABLE"],
  ["7", "Sophiya Nepali", "7", "2.15", "COMPLETED", "5", "ONE", "ACCEPTABLE"],
  ["1", "Ishup Gurung", "1", "3.75", "COMPLETED", "2", "TWO", "OUTSTANDING"],
  ["2", "Renisha Ale", "2", "3.6500000000000004", "COMPLETED", "3", "TWO", "OUTSTANDING"],
  ["3", "Anuska Kumal", "3", "2.95", "COMPLETED", "6", "TWO", "VERY GOOD"],
  ["4", "Sajina Ale", "4", "2.05", "COMPLETED", "9", "TWO", "ACCEPTABLE"],
  ["5", "Sujan Sunar", "5", "2.15", "COMPLETED", "8", "TWO", "ACCEPTABLE"],
  ["6", "Agniya Fal Magar", "6", "3.75", "COMPLETED", "1", "TWO", "OUTSTANDING"],
  ["7", "Lusan Gurung", "7", "3.2500000000000004", "COMPLETED", "5", "TWO", "EXCELLENT"],
  ["8", "Sandhya Sunar", "8", "2.85", "COMPLETED", "7", "TWO", "VERY GOOD"],
  ["9", "Deepshika BK", "9", "3.3500000000000005", "COMPLETED", "4", "TWO", "EXCELLENT"],
  ["10", "Khusi Ale", "10", "1.1", "WITHHELD", "10", "TWO", "NOTGRADED"],
  ["1", "Khusbu Thapa", "1", "3.6", "COMPLETED", "2", "THREE", "OUTSTANDING"],
  ["2", "Nabina Pariyar", "2", "3.4499999999999997", "COMPLETED", "3", "THREE", "EXCELLENT"],
  ["3", "Sanil Pariyar", "3", "2.5", "COMPLETED", "5", "THREE", "GOOD"],
  ["4", "Khim Maya Gurung", "4", "2.2", "COMPLETED", "8", "THREE", "ACCEPTABLE"],
  ["5", "Soni Bk", "5", "2.4", "COMPLETED", "6", "THREE", "GOOD"],
  ["6", "Mukesh Kumal", "6", "2", "COMPLETED", "9", "THREE", "ACCEPTABLE"],
  ["7", "Aakriti Ale", "7", "3.05", "COMPLETED", "4", "THREE", "VERY GOOD"],
  ["8", "Sijan Timsina", "8", "3.6500000000000004", "COMPLETED", "1", "THREE", "OUTSTANDING"],
  ["9", "Aayushka Bk", "9", "2.1", "COMPLETED", "7", "THREE", "ACCEPTABLE"],
  ["1", "Shristi Kumal", "1", "2.9499999999999997", "COMPLETED", "2", "FOUR", "VERY GOOD"],
  ["2", "Madhu Jaiswal", "2", "3.1", "COMPLETED", "1", "FOUR", "VERY GOOD"],
  ["3", "Sushal BK", "3", "2.6", "COMPLETED", "7", "FOUR", "GOOD"],
  ["4", "Preeti Kumal", "4", "2.7", "COMPLETED", "5", "FOUR", "GOOD"],
  ["5", "Subina Rana", "5", "2.8499999999999996", "COMPLETED", "3", "FOUR", "VERY GOOD"],
  ["6", "Dipshana BK", "6", "2.15", "COMPLETED", "12", "FOUR", "ACCEPTABLE"],
  ["7", "Prajwal Kumal", "7", "2.25", "COMPLETED", "9", "FOUR", "ACCEPTABLE"],
  ["8", "Bipana BK", "8", "2.6500000000000004", "COMPLETED", "6", "FOUR", "GOOD"],
  ["9", "Bibek Sunar", "9", "2.25", "COMPLETED", "11", "FOUR", "ACCEPTABLE"],
  ["10", "Basanta Kumal", "10", "2.05", "COMPLETED", "12", "FOUR", "ACCEPTABLE"],
  ["11", "Sajan Ale", "11", "2.35", "COMPLETED", "9", "FOUR", "ACCEPTABLE"],
  ["12", "Girish Kumal", "12", "1.95", "COMPLETED", "15", "FOUR", "BASIC"],
  ["13", "Alisha Kumal", "13", "2", "COMPLETED", "14", "FOUR", "ACCEPTABLE"],
  ["14", "Nabin Kumal", "14", "0", "WITHHELD", "18", "FOUR", "NOTGRADED"],
  ["15", "Sushil Ramdam", "15", "0.2", "WITHHELD", "17", "FOUR", "NOTGRADED"],
  ["16", "Laxmi Chaudhary", "16", "2.5", "COMPLETED", "8", "FOUR", "GOOD"],
  ["18", "Sijan Thapa Magar", "18", "0.25", "WITHHELD", "16", "FOUR", "NOTGRADED"],
  ["19", "Anu Bk", "19", "2.85", "COMPLETED", "4", "FOUR", "VERY GOOD"],
  ["1", "Shashmita Kanu Magar", "1", "3.4", "PASS", "1", "FIVE", "EXCELLENT"],
  ["2", "Rupa Bk", "2", "3.1500000000000004", "PASS", "4", "FIVE", "VERY GOOD"],
  ["3", "Sharmila Bk", "3", "3.3", "PASS", "2", "FIVE", "EXCELLENT"],
  ["4", "Suman Sunar", "4", "2.85", "PASS", "8", "FIVE", "VERY GOOD"],
  ["5", "Lovely Jaiswal", "5", "3.2", "PASS", "3", "FIVE", "EXCELLENT"],
  ["6", "Aliza Ale", "6", "2.95", "PASS", "6", "FIVE", "VERY GOOD"],
  ["7", "Nishana Thapa", "7", "2.9", "PASS", "7", "FIVE", "VERY GOOD"],
  ["8", "Megh Bahadur BK", "8", "2.5500000000000003", "PASS", "11", "FIVE", "GOOD"],
  ["9", "Nabin Pariyar", "9", "2.7", "PASS", "10", "FIVE", "GOOD"],
  ["10", "Sajya Ale", "10", "2.15", "PASS", "14", "FIVE", "ACCEPTABLE"],
  ["11", "Khusbu Thapa", "11", "2.2", "PASS", "12", "FIVE", "ACCEPTABLE"],
  ["12", "Simran Thapa", "12", "1.95", "PASS", "17", "FIVE", "BASIC"],
  ["13", "Prinshesh Thapa", "13", "2.2", "PASS", "13", "FIVE", "ACCEPTABLE"],
  ["14", "Sushant Thapa", "14", "2.1", "PASS", "15", "FIVE", "ACCEPTABLE"],
  ["15", "Puran Shrestha", "15", "2", "PASS", "18", "FIVE", "ACCEPTABLE"],
  ["16", "Yukesh Nepali", "16", "2.05", "PASS", "16", "FIVE", "ACCEPTABLE"],
  ["17", "Ankit Gurung ", "17", "2.8", "PASS", "9", "FIVE", "VERY GOOD"],
  ["18", "Suan Sunar", "18", "3.0999999999999996", "PASS", "5", "FIVE", "VERY GOOD"],
  ["1", "Ashika Bastola", "1", "3.2125000000000004", "PASS", "4", "SIX", "EXCELLENT"],
  ["2", "Anup Thapa", "2", "3.375", "PASS", "2", "SIX", "EXCELLENT"],
  ["3", "Ashmita Sunar", "3", "2.3125", "PASS", "13", "SIX", "ACCEPTABLE"],
  ["4", "Ashik Gurung", "4", "2.15", "PASS", "16", "SIX", "ACCEPTABLE"],
  ["5", "Bimal Kumal", "5", "2.1625", "PASS", "15", "SIX", "ACCEPTABLE"],
  ["6", "Binita Kumal", "6", "2.1125000000000003", "PASS", "17", "SIX", "ACCEPTABLE"],
  ["7", "Deepshika Thapa", "7", "2.7875", "PASS", "6", "SIX", "GOOD"],
  ["8", "Kamana Gurung", "8", "2.6125000000000003", "PASS", "10", "SIX", "GOOD"],
  ["9", "Mausami Thapa", "9", "3.2624999999999997", "PASS", "3", "SIX", "EXCELLENT"],
  ["10", "Sumana Ale", "10", "2.7875", "PASS", "6", "SIX", "GOOD"],
  ["11", "Pratima Sunar", "11", "2.525", "PASS", "11", "SIX", "GOOD"],
  ["12", "Pratigya Sunar", "12", "3.425", "PASS", "1", "SIX", "EXCELLENT"],
  ["13", "Rikzen Lama", "13", "2.725", "PASS", "8", "SIX", "GOOD"],
  ["14", "Rasmi Thapa", "14", "2.375", "PASS", "12", "SIX", "ACCEPTABLE"],
  ["15", "Riya Shrestha", "15", "2.6750000000000003", "PASS", "9", "SIX", "GOOD"],
  ["16", "Sharmila Bk", "16", "2.2", "PASS", "14", "SIX", "ACCEPTABLE"],
  ["17", "Sabin Thapa", "17", "2.1", "PASS", "18", "SIX", "ACCEPTABLE"],
  ["18", "Kusum Bk", "18", "3.0749999999999997", "PASS", "5", "SIX", "VERY GOOD"],
  ["1", "Aashika Thapa", "1", "2.625", "PASS", "19", "SEVEN", "GOOD"],
  ["2", "Ashmita BK", "2", "2.7", "PASS", "14", "SEVEN", "GOOD"],
  ["3", "Amit Bastola", "3", "2.7750000000000004", "PASS", "9", "SEVEN", "GOOD"],
  ["4", "Arpan Paudel", "4", "2.625", "PASS", "19", "SEVEN", "GOOD"],
  ["5", "Anish Thapa", "5", "3.0250000000000004", "PASS", "4", "SEVEN", "VERY GOOD"],
  ["6", "Anush Sunar", "6", "2.8000000000000003", "PASS", "10", "SEVEN", "VERY GOOD"],
  ["7", "Ashim Gurung", "7", "1.3250000000000002", "Fail", "26", "SEVEN", "NOTGRADED"],
  ["8", "Anjali BK", "8", "1.575", "Fail", "24", "SEVEN", "NOTGRADED"],
  ["9", "Amrita Sunar", "9", "2.675", "PASS", "17", "SEVEN", "GOOD"],
  ["10", "Barsha Ale", "10", "2.5500000000000003", "PASS", "18", "SEVEN", "GOOD"],
  ["11", "Dinesh Kumal", "11", "2.475", "PASS", "21", "SEVEN", "GOOD"],
  ["12", "Deepika Kumal", "12", "1.9999999999999998", "Fail", "22", "SEVEN", "ACCEPTABLE"],
  ["13", "Govinda Sigdel", "13", "1.05", "Fail", "28", "SEVEN", "NOTGRADED"],
  ["14", "Insha Thapa", "14", "2.95", "PASS", "6", "SEVEN", "GOOD"],
  ["15", "Muskan Sunar", "15", "2.75", "PASS", "13", "SEVEN", "GOOD"],
  ["16", "Malish Thapa", "16", "3.1000000000000005", "PASS", "3", "SEVEN", "VERY GOOD"],
  ["17", "Pramila Sunar", "17", "2.8249999999999997", "PASS", "8", "SEVEN", "VERY GOOD"],
  ["18", "Pritam Kumal", "18", "2.775", "PASS", "12", "SEVEN", "GOOD"],
  ["19", "Subin Rana", "19", "2.9000000000000004", "PASS", "5", "SEVEN", "VERY GOOD"],
  ["20", "Shekhar Thapa", "20", "3.2000000000000006", "PASS", "2", "SEVEN", "EXCELLENT"],
  ["21", "Sharad Ale", "21", "1.35", "Fail", "23", "SEVEN", "NOTGRADED"],
  ["22", "Sujan Kumal", "22", "1.275", "Fail", "27", "SEVEN", "NOTGRADED"],
  ["23", "Sajina Shrestha", "23", "2.6", "PASS", "15", "SEVEN", "GOOD"],
  ["24", "Sajina Malla", "24", "2.7", "PASS", "16", "SEVEN", "GOOD"],
  ["25", "Shisir Ale", "25", "1.2", "Fail", "25", "SEVEN", "NOTGRADED"],
  ["26", "Sadhana Ale", "26", "2.9", "PASS", "7", "SEVEN", "VERY GOOD"],
  ["27", "Shirish Ale", "27", "3.45", "PASS", "1", "SEVEN", "EXCELLENT"],
  ["28", "Yuna Thapa", "28", "2.725", "PASS", "11", "SEVEN", "GOOD"],
  ["1", "Anisha Thapa", "1", "3.1", "PASS", "1", "NINE", "VERY GOOD"],
  ["2", "Ashmita Jhedi Magar", "2", "2.75", "PASS", "4", "NINE", "GOOD"],
  ["3", "Bhal Maya Nepali", "3", "1.9", "PASS", "27", "NINE", "BASIC"],
  ["4", "Binisha Rahapal", "4", "2.05", "PASS", "16", "NINE", "ACCEPTABLE"],
  ["5", "Dan Bdr Thapa Magar", "5", "0.65", "WITHHELD", "31", "NINE", "NOTGRADED"],
  ["6", "Dipesh Kumal", "6", "0.45", "WITHHELD", "30", "NINE", "NOTGRADED"],
  ["7", "Hari Thapa", "7", "0.8999999999999999", "WITHHELD", "29", "NINE", "NOTGRADED"],
  ["8", "Ishwor Shrestha", "8", "1.9", "PASS", "26", "NINE", "BASIC"],
  ["9", "Keshar Thapa Magar", "9", "2.0999999999999996", "PASS", "14", "NINE", "ACCEPTABLE"],
  ["10", "Kriti Thapa", "10", "1.95", "PASS", "22", "NINE", "BASIC"],
  ["11", "Kul Bahadur Nepali", "11", "2.2", "PASS", "12", "NINE", "ACCEPTABLE"],
  ["12", "Manisha Thapa", "12", "2.3", "PASS", "8", "NINE", "ACCEPTABLE"],
  ["13", "Manita Kumal ", "13", "2.5", "PASS", "7", "NINE", "GOOD"],
  ["14", "Min Kumari Kumal", "14", "2.05", "PASS", "13", "NINE", "ACCEPTABLE"],
  ["15", "Namika Thapa", "15", "2.85", "PASS", "2", "NINE", "VERY GOOD"],
  ["16", "Nischal Kumal", "16", "1.9", "PASS", "25", "NINE", "BASIC"],
  ["17", "Pratikshya Gurung", "17", "2.55", "PASS", "5", "NINE", "GOOD"],
  ["18", "Rabina Chhetri", "18", "2.1", "PASS", "15", "NINE", "ACCEPTABLE"],
  ["19", "Rajani Kumal", "19", "1.9", "PASS", "23", "NINE", "BASIC"],
  ["20", "Reema Gurung", "20", "2.5999999999999996", "PASS", "6", "NINE", "GOOD"],
  ["21", "Renu Maya Ale", "21", "2.0999999999999996", "PASS", "11", "NINE", "ACCEPTABLE"],
  ["22", "Rohit Kumal", "22", "2", "PASS", "20", "NINE", "ACCEPTABLE"],
  ["23", "Roshan Baniya", "23", "2", "PASS", "21", "NINE", "ACCEPTABLE"],
  ["24", "Samana Ale Magar", "24", "2.8000000000000003", "PASS", "3", "NINE", "VERY GOOD"],
  ["25", "Sandhya Tmanag", "25", "1.95", "PASS", "24", "NINE", "BASIC"],
  ["26", "Sansil Saru", "26", "2.2", "PASS", "9", "NINE", "ACCEPTABLE"],
  ["27", "Sharmila Kumal", "27", "2.2", "PASS", "10", "NINE", "ACCEPTABLE"],
  ["28", "Shristi Pariyar", "28", "2.1", "PASS", "17", "NINE", "ACCEPTABLE"],
  ["29", "Subasna Rana", "29", "1.9", "PASS", "28", "NINE", "BASIC"],
  ["30", "Sunil Nepali", "30", "2.05", "PASS", "19", "NINE", "ACCEPTABLE"],
  ["31", "Sushil Kumal", "31", "2.05", "PASS", "18", "NINE", "ACCEPTABLE"],
];
export const headers = [
  "Roll No",
  "Name Of Student",
  "Date Of Birth",
  "GPA",
  "Result",
  "Rank",
  "Class",
];
