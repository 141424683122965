import React from "react";
import ReactDOM from "react-dom";
import "./index.css";

/* ======================= LAYOUTS ====================== */
import { Header, Footer } from "./Layouts";

/* ======================= SCREENS ====================== */
import { Home, Result } from "./Screen";
import { BrowserRouter, Route, Switch } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route exact path="/">
        <Header />
        <Home />
        <Footer />
      </Route>
      <Route exact path="/result">
        <Header />
        <Result />
        <Footer />
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
