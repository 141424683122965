import { getTeacherGuide } from "../../Firebase";
import { CardTeacherGuid } from "../../Layouts";

export default function TeacherGuide() {
  const teacherGuide = getTeacherGuide();

  console.log("Teacher Guide == ", teacherGuide);
  return (
    <div className="px-5 mt-10">
      <div className="text-center w-100 mb-10">
        <h1 className="text-3xl text-primary font-bold pt-10">Got Something for teacher too</h1>
      </div>
      <div className="md:px-10 flex flex-wrap justify-center md:justify-between">
        <CardTeacherGuid />
        <CardTeacherGuid /> <CardTeacherGuid />
        <CardTeacherGuid /> <CardTeacherGuid />
        <CardTeacherGuid />
      </div>
    </div>
  );
}
