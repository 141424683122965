export function CardTeacherGuid() {
  return (
    <div className="md:w-72 mx-5 w-full h-auto rounded-lg shadow-xl mb-9">
      <img
        src="https://pustakalaya.org/media/uploads/thumbnails/document/2020/08/13/Good_Teacher_My_Future.jpg"
        alt="nepali-teacher-guid"
        className="w-full h-72 rounded-t-lg"
      />
      <div className="p-5">
        <h1 className="text-lg font-bold text-green-600">Nanoleap Teacher Guide</h1>
        <p className="text-gray-500 font-semibold">By: Christine Morrow, John Ristvey</p>
      </div>
    </div>
  );
}
