export const Footer = () => {
  return (
    <div className="md:flex-row md:px-20 md:text-left flex flex-col text-center justify-between mt-20 bg-green-100  p-10">
      <div className="">
        <h1 className="text-2xl font-semibold text-primary">BINOD</h1>
        <div className="md:justify-start p-1 w-full justify-center flex items-center">
          <i className="fas fa-phone-alt"></i>
          <p className="text-md ml-3">9846116019</p>
        </div>
        <div className="md:justify-start p-1 w-full justify-center flex items-center">
          <i className="fas fa-envelope"></i>
          <p className="text-md ml-3">imbnod@gmail.com</p>
        </div>
        <div className="md:justify-start p-1 w-full justify-center flex items-center">
          <i className="fas fa-map-marker-alt"></i>
          <p className="text-md ml-3">Shuklagandaki-2</p>
        </div>
      </div>
      <div className="md:text-right text-center">
        <h1 className="text-xl font-semibold text-gray-500 mt-3">Quick Navigation</h1>
        <p className="p-1">Home</p>
        <p className="p-1">About Me</p>
        <p className="p-1">Result</p>
        <p className="p-1">Contact Me</p>
      </div>
    </div>
  );
};
