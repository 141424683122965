import { useEffect } from "react";
import Collections from "./Collections";
import LandingPage from "./LandingPage";
import TeacherGuide from "./TeacherGuide";

export function Home() {
  useEffect(() => {
    document.title = "Binod | Home";
  }, []);
  return (
    <>
      <LandingPage />
      <Collections />
      <TeacherGuide />
    </>
  );
}
