import { useEffect, useState } from "react";
import NavLinks from "./NavLinks";
import { Link } from "react-router-dom";
import SocialIcons from "./SoaiclIcons";
import { useLocation } from "react-router-dom";

export const Header = () => {
  const [headerShadow, setHeaderShadow] = useState("");
  const location = useLocation();

  const [showHeader, setShowHeader] = useState("hidden");
  const [primaryColor, setPrimaryColor] = useState("primary");

  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    setShowHeader("hidden");
    window.scrollTo(10, 10);
  }, [location]);

  useEffect(() => {
    setWindowHeight(window.innerHeight);
  }, []);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 20) {
      setHeaderShadow("shadow");
    } else {
      setHeaderShadow("");
    }

    if (window.scrollY >= windowHeight - 100 && window.scrollY < windowHeight * 2 - 100) {
      setPrimaryColor("secondary");
    } else if (window.scrollY >= windowHeight * 2 - 100) {
      setPrimaryColor("primary");
    } else {
      setPrimaryColor("primary");
    }
  });

  return (
    <div
      className={`${headerShadow} flex justify-between items-center h-16 bg-white fixed top-0 left-0 z-50 w-screen px-5`}
    >
      <div className="flex">
        <div
          className="mr-3 p-0 md:hidden"
          onClick={() => setShowHeader((old) => (old === "hidden" ? "block" : "hidden"))}
        >
          <i className="fas fa-bars text-xl"></i>
        </div>
        <h1 className={`font-bold text-2xl text-${primaryColor} cursor-pointer`}>Binod</h1>
      </div>
      <div
        className={`${showHeader} md:flex md:static md:shadow-none md:w-auto w-screen bg-white fixed top-16 left-0 shadow-md text-center `}
      >
        <ul className="bg-white md:flex">
          <Link to="/">
            <NavLinks>Home</NavLinks>
          </Link>
          <Link to="/about">
            <NavLinks>About</NavLinks>
          </Link>
          <Link to="/result">
            <NavLinks>Result</NavLinks>
          </Link>
          <Link to="/contact">
            <NavLinks>Contact</NavLinks>
          </Link>
        </ul>
      </div>
      <div className="md:justify-end">
        <SocialIcons name="facebook" />
        <SocialIcons name="twitter" />
        <SocialIcons name="instagram" />
        <SocialIcons name="youtube" />
      </div>
    </div>
  );
};

/*

md:hidden h-16 flex items-center fixed left-3 text-right z-50
bg-white w-full md:h-20 h-16 flex justify-between bg-yellow items-center md:px-10 px-5  fixed z-40 top-0 left-0 bg-primary-bg
h-20 flex items-center md:ml-0 ml-5

md:flex block md:mb-0 mb-3 bg-white text-center 
md:mt-0 mt-16 md:block md:relative md:bottom-0 md:pl-0 left-0 md:shadow-none md:text-center md:w-auto absolute -bottom-36 pl-5 w-full transition shadow-lg




*/
