export default function CollectionCard({ title }) {
  return (
    <div className="flex w-full text-white mb-5">
      <div className="flex w-full bg-secondary px-10 py-6 rounded-lg cursor-pointer justify-between items-center">
        <h1 className=" text-2xl">{title}</h1>
        <i className="fas fa-chevron-circle-down text-2xl"></i>
      </div>
    </div>
  );
}
