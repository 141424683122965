export const Button = ({
  children,
  className,
  startIcon,
  endIcon,
  ...rest
}) => {
  return (
    <button
      className={`py-3 px-7 bg-primary text-white rounded inline-flex hover:bg-secondary  ${
        className && className
      }`}
      {...rest}
    >
      {startIcon ? <div className="mr-3">{startIcon}</div> : null}
      {children}
      {startIcon ? <div className="ml-3"></div> : null}
    </button>
  );
};
