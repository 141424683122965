import { Button } from "../../Components";
import profileImg from "../../Images/profile.svg";
import { Link } from "react-router-dom";

export default function LandingPage() {
  return (
    <div className="md:h-screen md:text-left md:px-10 lg:20 md:flex-row flex-col-reverse md:justify-between h-auto pt-16 flex flex-wrap-reverse text-center items-center justify-center px-5">
      <div className="">
        <h1 className="text-primary font-bold text-5xl mb-3">Hi I'm BINOD</h1>
        <p className="text-blue-500 text-2xl mb-2 font-semibold">
          Teacher at Saraswoti Namuna School
        </p>
        <p className="text-gray-500 font-medium">
          This website is build for helping students to increase their learning capacity <br /> and
          teacher to increase their teaching skill.
        </p>
        <div className="mt-10 md:mt-2">
          <Button startIcon={<i className="fas fa-book" />} className="md:w-auto w-full">
            Books and Formula
          </Button>
          <Link to="/result">
            <Button
              startIcon={<i className="fas fa-chalkboard-teacher"></i>}
              className="md:w-auto md:ml-2 w-full mt-2"
            >
              Result
            </Button>
          </Link>
        </div>
      </div>

      <div className="mb-10">
        <img
          className="m-0 rounded-3xl"
          src={profileImg}
          alt="my-profile"
          style={{ width: "380px", height: "400px" }}
        />
      </div>
    </div>
  );
}

/**
 *  min-h-screen flex md:justify-between justify-start lg:px-32 md:px-20 px-10 md:items-center font-bold md:flex-wrap-reverse md:text-left text-center
 *  md:w-auto w-screen text-center h-auto md:pt-0 pt-5
 *  my-1 primary-text md:text-7xl text-6xl text-primary
 *  text-gray-600 md:text-lg text-sm
 *  text-gray-500 font-medium
 *  mt-2
 *  flex md:w-auto w-full md:justify-end justify-center md:h-full md:items-center 
 *  
 * 
 * 
 * 
 * 
 * 
 * 
 * 
 * 


 */
