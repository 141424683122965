import { useState, useEffect } from "react";
import { data } from "./data";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

export const Result = () => {
  useEffect(() => {
    document.title = "Result of Saraswoti Namuna School, At Glance";
  }, []);

  const classOption = [
    "Choose Your Class",
    "NURSERY",
    "LKG",
    "UKG",
    "ONE",
    "TWO",
    "THREE",
    "FOUR",
    "FIVE",
    "SIX",
    "SEVEN",
    "NINE",
  ];

  const defaultOption = classOption[0];

  const [resultState, setResultState] = useState({
    studentId: 0,
    visible: false,
    error: false,
  });

  const [userClass, setUserClass] = useState("");
  const [userRollNo, setUserRollNo] = useState("");
  const onshowresult = () => {
    for (let i = 0; i < data.length; i++) {
      if (data[i][6] === userClass) {
        console.log("Class Milo");
        if (data[i][0] === userRollNo) {
          console.log("Roll No Milo");
          setResultState(() => ({ studentId: i, visible: true, error: false }));
          break;
        } else {
          setResultState((prevData) => ({ ...prevData, visible: false, error: true }));
        }
      }
    }
  };

  return (
    <div className="md:min-h-screen md:p-20 md:pt-20 pt-20 my-auto mx-5">
      <div className="flex flex-col justify-center items-center bg-primary-light md:m-20 m-0 md:p-20 p-5 md:w-auto w-full rounded-lg">
        <h1 className="text-center  md:text-3xl text-lg mb-5 font-semibold text-secondary-dark">
          Saraswoti Namuna School Result, At Glance
        </h1>
        <Dropdown
          options={classOption}
          onChange={(e) => setUserClass(e.value)}
          value={defaultOption}
          placeholder="Select an option"
          className="w-full"
        />
        ;
        <input
          value={userRollNo}
          onChange={(e) => setUserRollNo(e.target.value)}
          type="number"
          placeholder="Enter Your Roll Number"
          className="w-full border-2-gray-600 mt-2 mb-5 px-5 py-2 border-gray-500 rounded-lg bg-gray-100 outline-none"
        />
        <button onClick={() => onshowresult()} className="w-full bg-blue-600 rounded-lg px-5 py-2">
          See Result
        </button>
      </div>
      {resultState.visible === true ? (
        <div className="bg-blue-100 p-5 my-20 w-full mx-auto rounded-xl">
          <p className="text-lg">Name : {data[resultState.studentId][1]}</p>
          <p className="text-lg">Roll No : {data[resultState.studentId][0]}</p>
          <p className="text-lg">Class : {data[resultState.studentId][6]}</p>
          <div
            className={`bg-green-400 bg-${
              data[resultState.studentId][4] === "PASS" ||
              data[resultState.studentId][4] === "COMPLETED"
                ? "green"
                : "red"
            }-400 p-5 rounded-lg my-10`}
          >
            <h1 className="text-2xl text-center">{data[resultState.studentId][7]} !</h1>
            <br />
            <h1 className="text-lg">Result : {data[resultState.studentId][4]} </h1>
            <h1 className="text-lg">
              GPA : {parseFloat(data[resultState.studentId][3]).toFixed(2)}{" "}
            </h1>
            <h1 className="text-lg">Rank : {data[resultState.studentId][5]} </h1>
          </div>
        </div>
      ) : null}
      {resultState.error === true ? (
        <div className="p-10 bg-red-400 text-center mt-10 rounded-lg">
          <h1 className="text-2xl">User Not Found !</h1>
        </div>
      ) : null}
    </div>
  );
};
