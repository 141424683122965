import { useEffect, useState } from "react"
import { firestore } from "./config"

export const useToGetTeacherGuide = () => {
    const [teacherGuide,setTeacherGuide] = useState()
    
    useEffect(()=>{
        firestore.collection("Teacher Guide").onSnapshot((snapShot)=>{
            setTeacherGuide(()=>{
                snapShot.docs.map((doc)=>{return {...doc.data()}})
            })
        })
    },[])

    return teacherGuide
}