import CollectionCard from "./CollectionCard";

export default function Collections() {
  return (
    <div className="mt-20 text-center px-5 md:px-10 md:mt-0">
      <div className="">
        <h1 className="text-4xl text-secondary font-bold">My Collections</h1>
        <p className="text-center w-100 mb-10 text-lg text-gray-500 mt-2">
          of Books, Forluma & Notes to help you out
        </p>
      </div>
      <div className="">
        <CollectionCard title="Books" />
        <CollectionCard title="Formula" />
        <CollectionCard title="Notes" />
      </div>
    </div>
  );
}
